import React from "react";
import { Pic } from "./pic";

console.log("line 21", `${process.env.PUBLIC_URL}/img/bank/bob.jpg`);
export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="home-loans-section">
          {/* <h3>Home Loans</h3> */}
          <div className="section-title">
            <h2>Home Loans We Deals In... </h2>
            <ul>
              <li>Flexible home loan options tailored to your needs.</li>
              <li>Competitive interest rates for various loan terms.</li>
              <li>
                Expert guidance throughout the application and approval process.
              </li>
              <li>
                Assistance with understanding eligibility criteria and
                documentation requirements.
              </li>
              <li>
                Support for first-time homebuyers, including information on
                government schemes and grants.
              </li>
              <li>
                Online tools and calculators to estimate loan repayments and
                affordability.
              </li>
              <li>
                Options for refinancing existing mortgages for better terms.
              </li>
              <li>
                Responsive customer service to address queries and concerns.
              </li>
              <li>Transparent fee structures with no hidden charges.</li>
            </ul>
          </div>
        </div>

        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-2">
                  {/* <i className={d.icon}></i> */}
                  <Pic pic={`${process.env.PUBLIC_URL}/${d.pic}`} />
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    {/* <p>{d.text}</p> */}
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
