import { Image } from "./image";
import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

export const Gallery = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideChange = (event) => {
    setCurrentIndex(event.item);
  };

  const galleryItems = props.data
    ? props.data.map((d, i) => (
        <img key={`${d.title}-${i}`} 
        src={d.smallImage} 
        alt={d.title} 
        style={{ maxWidth: "100%", maxHeight: "80%", margin: "0 auto" }}
        />
      ))
    : [<div>Loading...</div>];

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          {/* <p>Here are our sites</p> */}
        </div>
        <AliceCarousel
          items={galleryItems}
          autoPlay
          autoPlayInterval={2000}
          infinite
          disableButtonsControls // Hide next and previous buttons
          dotsDisabled={false} // Show pagination dots
          mouseTracking // Enable mouse drag
          slideToIndex={currentIndex} // Set initial slide index
          onSlideChanged={handleSlideChange} // Handle slide change event
        />
      </div>
    </div>
  );
};
